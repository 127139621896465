import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyA6kj92CWcZvdsbi3n_DwmtCNlCB9hfe3w",
  authDomain: "yamar-f1189.firebaseapp.com",
  projectId: "yamar-f1189",
  storageBucket: "yamar-f1189.appspot.com",
  messagingSenderId: "629717680466",
  appId: "1:629717680466:web:772ee83ddedd324761ba8b",
  measurementId: "G-QJ37CB42DM"
};
export async function getAllOfCollection(collection) {
  let data = [];
  let querySnapshot = await firebase.firestore().collection(collection).get();
  querySnapshot.forEach(function (doc) {
    if (doc.exists) {
      data.push(doc.data());
    } else {
    }
  });
  return data;
}

export function uniqueID() {
  function chr4() {
    return Math.random().toString(16).slice(-4);
  }
  return (
    chr4() +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    "-" +
    chr4() +
    chr4() +
    chr4()
  );
}
firebase.initializeApp(firebaseConfig);

export default firebase;
