import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HeaderNav from "./components/Nav/HeaderNav";
import About from "./pages/About";
import Home from "./pages/Home";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataProtectionPolicy from "./pages/DataProtectionPolicy";
import DataRetentionPolicy from "./pages/DataRetentionPolicy";
import GeolocationPolicies from "./pages/GeolocationPolicies";
import PrivacyAgreement from "./pages/PrivacyAgreement";
import TermsAndConfitions from "./pages/TermsAndConfitions";
import Footer from "./components/footer/Footer";
import Contact from "./pages/Contact";
import useData from "./hooks/useData";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
function App() {
  const { } = useData();
  const website_data = useSelector(state => state.website_data)
  const [data, setData] = useState(website_data ?? [])
  const [aboutData, setAboutData] = useState()




  const homePageData = () => {
    let res = website_data?.find((i) => {
      if (i?.id === 'sections-data') {
        return i?.data
      }
    })
    return res
  }
  useEffect(() => {
    if (website_data?.length > 0) {
      let res = website_data?.find(i => i?.id === 'about-us')
      setData(website_data ?? [])
      setAboutData(res)
    }
  }, [website_data])
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <HeaderNav />
        <Routes>
          <Route path="/" element={<Home data={homePageData()} />} />
          <Route path="/about" element={<About aboutData={aboutData} />} />
          <Route path="/contact" element={<Contact contactData={aboutData} />} />

          <Route path="/privacy-agreement" element={<PrivacyAgreement />} />
          <Route path="/terms-conditions" element={<TermsAndConfitions />} />
          <Route path="/protection-policy" element={<DataProtectionPolicy />} />
          <Route path="/retention-policy" element={<DataRetentionPolicy />} />
          <Route path="/geo-policies" element={<GeolocationPolicies />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
