export const termsAndConditions = [
  {
    section: "License and Access",
    content: [
      "1.1 Subject to your compliance with these Terms, the Company grants you a non-exclusive, non-transferable, revocable license to use the Application for your personal, non-commercial purposes.",
      "1.2 You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Application, except as explicitly permitted by these Terms.",
    ],
  },
  {
    section: "User Account",
    content: [
      "2.1 In order to access certain features of the Application, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
      "2.2 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify the Company immediately of any unauthorized access to or use of your account or any other breach of security.",
    ],
  },
  {
    section: "User Conduct",
    content: [
      "3.1 You agree not to:",
      "  a. Use the Application for any illegal purpose or in violation of any applicable laws.",
      "  b. Post or transmit any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.",
      "  c. Interfere with or disrupt the integrity or performance of the Application.",
      "  d. Attempt to gain unauthorized access to the Application, user accounts, computer systems, or networks connected to the Application.",
      "  e. Users must be eighteen years of age or older to utilize the application and its functions.",
      "  f. User must be physically located and their billing location details within the countries the application currently accepts users from. If the user is found to have utilized a different address in their account, their account will be immediately terminated.",
    ],
  },
  {
    section: "Intellectual Property Rights",
    content: [
      "4.1 The Application, including its content, features, and functionality, is owned by the Company or its licensors and is protected by intellectual property laws.",
      "4.2 You may not use, copy, reproduce, distribute, transmit, broadcast, display, sell, license, or otherwise exploit any content on the Application for any other purposes without the prior written consent of the Company.",
    ],
  },
  {
    section: "Privacy Policy",
    content: [
      "5.1 Our Privacy Policy also governs your use of the Application. By using the Application, you consent to the terms of the Privacy Policy.",
    ],
  },
  {
    section: "Termination",
    content: [
      "6.1 The Company reserves the right to terminate, suspend, or restrict your access to the Application at any time without notice for any reason.",
      "6.2 Upon termination, all rights and licenses granted to you under these Terms will immediately cease.",
    ],
  },
  {
    section: "Limitation of Liability",
    content: [
      "7.1 To the fullest extent permitted by applicable law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.",
    ],
  },
  {
    section: "Governing Law",
    content: [
      "8.1 These Terms are governed by and construed in accordance with the laws of Florida, without regard to its conflict of law principles.",
    ],
  },
  {
    section: "Changes to Terms",
    content: [
      "9.1 The Company reserves the right to update or modify these Terms at any time without prior notice. Any changes will be effective immediately upon posting. Your continued use of the Application after such changes constitutes your acceptance of the revised Terms.",
    ],
  },
  {
    section: "Contact Information",
    content: [
      "10.1 For any inquiries or concerns regarding these Terms, please contact the Company at 3475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville, Florida 32225.",
    ],
  },
];
