import React, { useState } from "react";
import { Input, Button, Spinner } from "reactstrap";
import firebase, { uniqueID } from "../../config/firebase";
import { toast } from "react-toastify";
const ReminderSignupInput = ({ color, contents }) => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      const emailSnapshot = await firebase
        .firestore()
        .collection("reminderEmails")
        .where("email", "==", email)
        .get();

      if (emailSnapshot.empty) {
        console.log("inside snapshot");
        await firebase.firestore().collection("reminderEmails").add({
          email: email,
          _id: uniqueID()
        });

        toast.success(`Email ${email} added successfully.`);
        setLoader(false);
        setEmail("");
      } else {
        toast.error(`Email ${email} already exists.`);
        setLoader(false);
        setEmail("");
      }
    } catch (error) {
      console.error("Error submitting email:", error.message);
      setLoader(false);
      setEmail("");
    }
  };

  return (
    <form onSubmit={(e) => handleSubmitEmail(e)}>
      <div className="reminder__input__parent__div">
        <Input
          value={email}
          type="email"
          placeholder="someone@example.com"
          className="reminder__input border-radius py-2 px-3 border border-dark"
          required
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mt-3">
        <small style={{ color: color }}>
          {contents?.note ?? ''}
        </small>
      </div>
      <Button
        color="success"
        size="md"
        type="submit"
        className="submit__btn mt-0 mt-lg-3 border-radius "
      >
        {loader ? <Spinner size={"sm"} /> : "Submit"}
      </Button>
    </form>
  );
};

export default ReminderSignupInput;
