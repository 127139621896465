import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import SliderItemOne from "../components/ShopSliderSubItems/SliderItemOne";
import SliderItemThree from "../components/ShopSliderSubItems/SliderItemThree";
import SliderItemTwo from "../components/ShopSliderSubItems/SliderItemTwo";
function Home({ data }) {
  const [arr, setarr] = useState(data?.data ?? [])
  useEffect(() => {
    if (data) {
      setarr(data?.data ?? [])
    }
  }, [data])
  return (
    <>
      <Container fluid className="py-5 py-lg-0 section-one">
        <SliderItemOne contents={arr[0]} />
      </Container>
      <Container fluid className="py-5 py-lg-0 section-two">
        <SliderItemTwo contents={arr[1]} />
      </Container>
      <Container fluid className="py-5 py-lg-0 section-three">
        <SliderItemThree contents={arr[2]} />
      </Container>
    </>
  );
}

export default Home;
