import React from "react";
import { Col, Container, Row } from "reactstrap";
import { dataRetentionPolicy } from "../dataRetentionPolicyData";

const DataRetentionPolicy = () => {
  return (
    <Container className="my-5 ">
      <Row>
        <Col md={10} className="mx-auto mt-5">
          <div className="text-center">
            <h3>Ya’Mar Data Retention Policy</h3>
            <i>Effective as of December 11, 2023</i>
          </div>
          {dataRetentionPolicy.map((section, index) => (
            <div key={index}>
              <i>{section.section}</i>
              {Array.isArray(section.content) && (
                <div className="my-3">
                  {section.content.map((paragraph, i) => (
                    <p className="mb-3" key={i}>
                      {paragraph}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default DataRetentionPolicy;
