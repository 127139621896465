import React, { useState } from "react";
import logo from "../../assets/imgs/Logo.png";
import { Link, useLocation } from "react-router-dom";

function HeaderNav() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const location = useLocation();

  const dropDownLinks = [
    // {
    //   name: "Privacy Agreement",
    //   path: "/privacy-agreement",
    // },
    // {
    //   name: "Terms & Conditions",
    //   path: "/terms-conditions",
    // },
    // {
    //   name: "Personal Statement",
    //   path: "/web-statement",
    // },
    {
      name: "Data Protection Policy",
      path: "/protection-policy",
    },
    {
      name: "Data Retention Policy",
      path: "/retention-policy",
    },
    {
      name: "GeoLocation Policies",
      path: "/geo-policies",
    },
  ];
  return (
    <div>
      <nav
        className={`navbar fixed-top navbar-expand-lg  w-100 px-2 px-lg-5   
        
        `}
      >
        {/* ${
          location?.pathname === "/"
            ? "fixed-top"
            : "bg-purple position-relative fixed-top"
        } */}
        <div className="container-fluid">
          <a className="navbar-brand mx-0 mx-lg-5" href="#">
            <Link
              className="nav-link active text-white"
              aria-current="page"
              to="/"
            >
              <img src={logo} width={100} alt="" />
            </Link>
          </a>
          {/* <button
            className="navbar-toggler bg-white text-white"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          {/* <div className="collapse navbar-collapse mt-3" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link text-white" to="/about">
                  About Us
                </Link>
              </li>

              <li class="nav-item dropdown text-white">
                <a
                  class="nav-link dropdown-toggle text-white"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Privacy & Terms
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  {dropDownLinks?.map((link, index) => {
                    return (
                      <>
                        <li>
                          <Link
                            className="dropdown-item primaryColor"
                            to={link?.path}
                          >
                            {link?.name}
                          </Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div> */}
        </div>
      </nav>
    </div>
  );
}

export default HeaderNav;
