import React from "react";
import { Col, Row } from "reactstrap";
import slider_three_img from "../../assets/imgs/thirdSlideImg.png";
import ReminderSignupInput from "../Input/ReminderSignupInput";

const SliderItemThree = ({ contents }) => {
  return (
    <Row className="  text-dark">
      <Col
        lg={6}
        md={6}
        sm={12}
        className="d-flex justify-content-center align-items-center order-2 order-lg-1"
      >
        <div className="contents-wrapper">
          <h2 className="slider__title">
            {contents?.title ?? ''}
          </h2>
          <p className="slider__subTitle">
            {contents?.description ?? ''}
          </p>
          <ReminderSignupInput color="black" contents={contents} />
        </div>
      </Col>
      <Col
        lg={6}
        md={6}
        sm={12}
        className="d-flex justify-content-center align-items-center order-1 order-lg-2"
      >
        <div>
          <img src={contents?.image ?? slider_three_img} height={"auto"} width={"100%"} />
        </div>
      </Col>
    </Row>
  );
};

export default SliderItemThree;
