import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import slider_right_img from "../../assets/imgs/heroImg.png";
import ReminderSignupInput from "../Input/ReminderSignupInput";
import wave from "../../assets/imgs/wave01.png";

const SliderItemOne = ({ contents }) => {
  console.log('✌️contents --->', contents);
  return (
    <>
      <Row className=" text-white   ">
        <Col
          lg={6}
          md={6}
          sm={12}
          className="d-flex justify-content-center flex-column order-2 order-lg-1 "
        >
          <div className="contents-wrapper ">
            <h2 className="my-3  slider__title text-white">
              {/* Shop, Sell & Create */}
              {contents?.title ?? ''}
            </h2>
            <p className="slider__subTitle text-white">
              {/* Your dynamic e-commerce <br />
              marketplace awaits! */}
              {contents?.description ?? ''}
            </p>

            <ReminderSignupInput color="#ccc" contents={contents} />
          </div>
        </Col>
        <Col
          lg={6}
          sm={12}
          md={6}
          className="d-flex justify-content-center align-items-center order-1 order-lg-2"
        >
          <img src={slider_right_img} width={"100%"} />
        </Col>
      </Row>
    </>
  );
};

export default SliderItemOne;
