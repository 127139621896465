import React from "react";
import { Col, Row } from "reactstrap";
import discover_sec_img from "../../assets/imgs/middleSlideImg.jpeg";
import ReminderSignupInput from "../Input/ReminderSignupInput";
const SliderItemTwo = ({ contents }) => {
  return (
    <>
      <Row className=" text-dark  ">
        <Col
          lg={6}
          sm={12}
          md={6}
          className="d-flex justify-content-center align-items-center "
        >
          <div>
            <img src={contents?.image ?? discover_sec_img} className="second__slide__img" />
          </div>
        </Col>
        <Col
          lg={6}
          md={6}
          sm={12}
          className="d-flex justify-content-center align-items-center   "
        >
          <div className="contents-wrapper">
            <h2 className=" slider__title ">
              {/* Discover Your Desired <br /> Products */}
              {contents?.title ?? ''}
            </h2>
            <p className="slider__subTitle ">
              {/* Explore our curated selection to find precisely what you are
              looking for! */}
              {contents?.description ?? ''}
            </p>
            <ReminderSignupInput color="black" contents={contents} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SliderItemTwo;
