import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

const About = ({ aboutData }) => {

  return (
    <Container className="my-5 pt-5">
      <Row>
        <Col className="mx-auto" md={6}>
          <div className="d-flex justify-content-between mb-3">
            <h3>About us</h3>
            <Link to="/" className="text-decoration-none">
              <Button className="bg-white text-dark rounded-pill">
                <FaArrowLeft className="mx-2" />
                Back
              </Button>
            </Link>
          </div>
          <p>
            {aboutData?.description}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
