import React from "react";
import { Col, Container, Row } from "reactstrap";
import { termsAndConditions } from "../termsAndConditionsData";

const TermsAndConfitions = () => {
  return (
    <Container>
      <Row>
        <Col md={10} className="mx-auto mt-5">
          <div className="text-center">
            <h2 className=" mt-5">
              Terms and Conditions for the Ya’Mar Application{" "}
            </h2>
            <p>Effective as of December 11, 2023</p>
          </div>
          <p>
            These terms and conditions ("Terms") constitute a legal agreement
            between you ("User" or "you") and Ya’Mar , a Store-front Reselling
            Application ("Company," "we," "us," or "our") regarding your use of
            Ya’Mar ("Application"). By accessing or using the Application, you
            agree to comply with and be bound by these Terms. If you do not
            agree with these Terms, please do not use the Application.
          </p>

          {termsAndConditions.map((section, index) => (
            <div key={index}>
              <i>{section.section}</i>
              <div className="my-3">
                {section.content.map((paragraph, i) => (
                  <p className="mb-0" key={i}>
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConfitions;
