import firebase from "firebase";
import { setWebsiteData } from "../store/actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
const useData = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const unsubscribeOrders = firebase
            .firestore()
            .collection("WebsiteContent")
            .onSnapshot((querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                });

                dispatch(setWebsiteData(data));
            });
        return () => {
            unsubscribeOrders();
        };
    }, []);

    return {};
};

export default useData;
