export const sections = [
  {
    title: "Introduction",
    content:
      "1.1 This Agreement outlines the privacy practices and policies of the Company concerning the collection, use, and protection of personal information provided by Users.",
  },
  {
    title: "Information Collected",
    content:
      "2.1 The Company may collect and process the following types of personal information:",
    subSections: [
      {
        title: "A. Contact Information:",
        items: [
          "Full name",
          "Email address",
          "Phone number",
          "Mailing address",
        ],
      },
      {
        title: "B. Account Information:",
        items: [
          "Username",
          "Password (hashed or encrypted)",
          "Account preferences",
          "Profile picture",
        ],
      },
      {
        title: "C. Payment Information:",
        items: [
          "Credit card details",
          "Debit card details",
          "Bank account information",
          "Billing address",
        ],
      },
      {
        title: "D. Order History:",
        items: [
          "Records of past purchases",
          "Order details (items purchased, quantities, prices)",
          "Delivery information",
        ],
      },
      {
        title: "E. Shopping Preferences:",
        items: ["Product preferences", "Wish lists", "Saved items or carts"],
      },
      {
        title: "F. Device Information:",
        items: [
          "Device type",
          "Operating system",
          "IP address",
          "Unique device identifiers (e.g., IMEI or UDID)",
        ],
      },
      {
        title: "G. Location Information:",
        items: [
          "Geo-location data (if the user allows access)",
          "Delivery addresses",
          "Store preferences based on location",
        ],
      },
      {
        title: "H. Communication Preferences:",
        items: [
          "Opt-in or opt-out preferences for marketing communications",
          "Communication history with customer support",
        ],
      },
      {
        title: "I. Social Media Information:",
        items: [
          "Social media profiles (if the user logs in or connects via social media)",
          "Social connections within the app (e.g., friends' wish lists)",
        ],
      },
      {
        title: "J. Customer Feedback:",
        items: [
          "Ratings and reviews",
          "Feedback and comments",
          "Cookies and similar technologies for tracking user behavior",
          "Analytics data for improving app performance and user experience",
        ],
      },
      {
        title: "K. User-generated Content:",
        items: ["Product reviews", "User-submitted images or videos"],
      },
    ],
  },
  {
    title: "Purpose of Collection",
    content:
      "3.1 The Company collects and processes personal information for the following purposes:",
    subSections: [
      {
        title: "A. Order Fulfillment:",
        items: [
          "To process and fulfill user orders for products and services.",
        ],
      },
      {
        title: "B. Account Management:",
        items: [
          "To create and manage user accounts, allowing users to access and track their orders, preferences, and settings.",
        ],
      },
      {
        title: "C. Communication:",
        items: [
          "To communicate with users regarding order confirmations, shipping updates, and customer support inquiries.",
        ],
      },
      {
        title: "D. Personalization:",
        items: [
          "To personalize the user experience by providing tailored product recommendations, promotions, and content based on user preferences and shopping history.",
        ],
      },
      {
        title: "E. Payment Processing:",
        items: [
          "To facilitate secure payment transactions and manage payment information for purchases.",
        ],
      },
      {
        title: "F. User Authentication:",
        items: [
          "To verify the identity of users during account creation and login processes.",
        ],
      },
      {
        title: "G. Analytics and Improvement:",
        items: [
          "To analyze user behavior, preferences, and interactions within the app to improve services, features, and overall user experience.",
        ],
      },
      {
        title: "H. Marketing and Promotions:",
        items: [
          "To send marketing communications, promotions, and advertisements based on user preferences, with the option for users to opt in or out.",
        ],
      },
      {
        title: "I. Customer Support:",
        items: [
          "To respond to customer inquiries, address concerns, and provide support related to products, orders, or account-related issues.",
        ],
      },
      {
        title: "J. Legal Compliance:",
        items: [
          "To comply with applicable laws and regulations related to data protection, consumer rights, and other legal requirements.",
        ],
      },
      {
        title: "K. Fraud Prevention:",
        items: [
          "To detect and prevent fraudulent activities, including unauthorized access, transactions, or account misuse.",
        ],
      },
      {
        title: "L. Research and Development:",
        items: [
          "To conduct research and development activities aimed at improving the app's features, services, and security.",
        ],
      },
      {
        title: "M. Social Features:",
        items: [
          "If applicable, to enable social features, such as connecting with friends, sharing wish lists, or recommending products.",
        ],
      },
      {
        title: "N. User Feedback:",
        items: [
          "To gather user feedback, reviews, and ratings to enhance product offerings and overall customer satisfaction.",
        ],
      },
    ],
  },
];
export const privacyPolicySections = [
  {
    title: "Consent",
    content:
      "4.1 By using the Company's services, the User consents to the collection, use, and processing of their personal information in accordance with this Agreement.",
  },
  {
    title: "Security Measures",
    content:
      "5.1 The Company will implement reasonable and appropriate security measures to protect the personal information from unauthorized access, disclosure, alteration, and destruction.",
  },
  {
    title: "Data Retention",
    content:
      "6.1 The Company will retain personal information for the duration necessary to fulfill the purposes outlined in this Agreement unless a longer retention period is required or permitted by law.",
  },
  {
    title: "Sharing of Information",
    content:
      "7.1 The Company may share personal information with third parties only for the purposes outlined in this Agreement or as required by law.",
  },
  {
    title: "User Rights",
    content: "8.1 Users have the right to:",
    subSections: [
      "- Access their personal information",
      "- Correct inaccuracies in their personal information",
      "- Withdraw consent",
      "- Request the deletion of their personal information, subject to legal requirements",
    ],
  },
  {
    title: "Changes to the Agreement",
    content:
      "9.1 The Company reserves the right to modify this Agreement at any time. Users will be notified of material changes.",
  },
  {
    title: "Governing Law",
    content:
      "10.1 This Agreement is governed by and construed in accordance with the laws of the United States and, where applicable, the laws of the state of Florida.",
  },
  {
    title: "Contact Information",
    content:
      "11.1 For any inquiries or concerns regarding this Agreement or the Company's privacy practices, please contact the Company at 3475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville, Florida 32225 USA.",
  },
];

export const indianPrivacysections = [
  {
    title: "Introduction",
    content:
      "1.1 This Agreement outlines the privacy practices and policies of the Company concerning the collection, use, and protection of personal information provided by Users.",
  },
  {
    title: "Information Collected",
    content:
      "2.1 The Company may collect and process the following types of personal information:",
    subSections: [
      {
        title: "H. Contact Information:",
        items: [
          "Full name",
          "Email address",
          "Phone number",
          "Mailing address",
        ],
      },
      {
        title: "I. Account Information:",
        items: [
          "Username",
          "Password (hashed or encrypted)",
          "Account preferences",
          "Profile picture",
        ],
      },
      {
        title: "J. Payment Information:",
        items: [
          "Credit card details",
          "Debit card details",
          "Bank account information",
          "Billing address",
        ],
      },
      {
        title: "K. Order History:",
        items: [
          "Records of past purchases",
          "Order details (items purchased, quantities, prices)",
          "Delivery information",
        ],
      },
      {
        title: "L. Shopping Preferences:",
        items: ["Product preferences", "Wish lists", "Saved items or carts"],
      },
      {
        title: "M. Device Information:",
        items: [
          "Device type",
          "Operating system",
          "IP address",
          "Unique device identifiers (e.g., IMEI or UDID)",
        ],
      },
      {
        title: "N. Location Information:",
        items: [
          "Geo-location data (if the user allows access)",
          "Delivery addresses",
          "Store preferences based on location",
        ],
      },
      {
        title: "H. Communication Preferences:",
        items: [
          "Opt-in or opt-out preferences for marketing communications",
          "Communication history with customer support",
        ],
      },
      {
        title: "II. Social Media Information:",
        items: [
          "Social media profiles (if the user logs in or connects via social media)",
          "Social connections within the app (e.g., friends' wish lists)",
        ],
      },
      {
        title: "J. Customer Feedback:",
        items: [
          "Ratings and reviews",
          "Feedback and comments",
          "Cookies and similar technologies for tracking user behavior",
          "Analytics data for improving app performance and user experience",
        ],
      },
      {
        title: "K. User-generated Content:",
        items: ["Product reviews", "User-submitted images or videos"],
      },
    ],
  },
  {
    title: "Purpose of Collection",
    content:
      "3.1 The Company collects and processes personal information for the following purposes:",
    subSections: [
      {
        title: "O. Order Fulfillment:",
        items: [
          "To process and fulfill user orders for products and services.",
        ],
      },
      {
        title: "P. Account Management:",
        items: [
          "To create and manage user accounts, allowing users to access and track their orders, preferences, and settings.",
        ],
      },
      {
        title: "Q. Communication:",
        items: [
          "To communicate with users regarding order confirmations, shipping updates, and customer support inquiries.",
        ],
      },
      {
        title: "R. Personalization:",
        items: [
          "To personalize the user experience by providing tailored product recommendations, promotions, and content based on user preferences and shopping history.",
        ],
      },
      {
        title: "S. Payment Processing:",
        items: [
          "To facilitate secure payment transactions and manage payment information for purchases.",
        ],
      },
      {
        title: "T. User Authentication:",
        items: [
          "To verify the identity of users during account creation and login processes.",
        ],
      },
      {
        title: "U. Analytics and Improvement:",
        items: [
          "To analyze user behavior, preferences, and interactions within the app to improve services, features, and overall user experience.",
        ],
      },
      {
        title: "V. Marketing and Promotions:",
        items: [
          "To send marketing communications, promotions, and advertisements based on user preferences, with the option for users to opt in or out.",
        ],
      },
      {
        title: "W. Customer Support:",
        items: [
          "To respond to customer inquiries, address concerns, and provide support related to products, orders, or account-related issues.",
        ],
      },
      {
        title: "X. Legal Compliance:",
        items: [
          "To comply with applicable laws and regulations related to data protection, consumer rights, and other legal requirements.",
        ],
      },
      {
        title: "Y. Fraud Prevention:",
        items: [
          "To detect and prevent fraudulent activities, including unauthorized access, transactions, or account misuse.",
        ],
      },
      {
        title: "Z. Research and Development:",
        items: [
          "To conduct research and development activities aimed at improving the app's features, services, and security.",
        ],
      },
      {
        title: "AA. Social Features:",
        items: [
          "If applicable, to enable social features, such as connecting with friends, sharing wish lists, or recommending products.",
        ],
      },
      {
        title: "BB. User Feedback:",
        items: [
          "To gather user feedback, reviews, and ratings to enhance product offerings and overall customer satisfaction.",
        ],
      },
    ],
  },
];
export const privacyPolicyIndianSections = [
  {
    title: "Consent",
    content:
      "4.1 By using the Company's services, the User consents to the collection, use, and processing of their personal information in accordance with this Agreement.",
  },
  {
    title: "Security Measures",
    content:
      "5.1 The Company will implement reasonable and appropriate security measures to protect the personal information from unauthorized access, disclosure, alteration, and destruction.",
  },
  {
    title: "Data Retention",
    content:
      "6.1 The Company will retain personal information for the duration necessary to fulfill the purposes outlined in this Agreement unless a longer retention period is required or permitted by law.",
  },
  {
    title: "Sharing of Information",
    content:
      "7.1 The Company may share personal information with third parties only for the purposes outlined in this Agreement or as required by law.",
  },
  {
    title: "User Rights",
    content: "8.1 Users have the right to:",
    subSections: [
      "- Access their personal information",
      "- Correct inaccuracies in their personal information",
      "- Withdraw consent",
      "- Request the deletion of their personal information, subject to legal requirements",
    ],
  },
  {
    title: "Changes to the Agreement",
    content:
      "9.1 The Company reserves the right to modify this Agreement at any time. Users will be notified of material changes.",
  },
  {
    title: "Governing Law",
    content:
      "10.1 This Agreement is governed by and construed in accordance with the laws of India. 10.2 The Indian Contract Act, 1872 is the governing law for contracts in India. As per Sec.2(h) of the Act, a contract is an agreement enforceable by law.",
  },
  {
    title: "Contact Information",
    content:
      "11.1 For any inquiries or concerns regarding this Agreement, please contact the Company at 13475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville, Florida 32225 USA.",
  },
];

export const canadianPrivacysections = [
  {
    title: "Introduction",
    content:
      "1.1 This Agreement outlines the privacy practices and policies of the Company concerning the collection, use, and protection of personal information provided by Users.",
  },
  {
    title: "Information Collected",
    content:
      "2.1 The Company may collect and process the following types of personal information:",
    subSections: [
      {
        title: "O. Contact Information:",
        items: [
          "Full name",
          "Email address",
          "Phone number",
          "Mailing address",
        ],
      },
      {
        title: "P. Account Information:",
        items: [
          "Username",
          "Password (hashed or encrypted)",
          "Account preferences",
          "Profile picture",
        ],
      },
      {
        title: "Q. Payment Information:",
        items: [
          "Credit card details",
          "Debit card details",
          "Bank account information",
          "Billing address",
        ],
      },
      {
        title: "R. Order History:",
        items: [
          "Records of past purchases",
          "Order details (items purchased, quantities, prices)",
          "Delivery information",
        ],
      },
      {
        title: "S. Shopping Preferences:",
        items: ["Product preferences", "Wish lists", "Saved items or carts"],
      },
      {
        title: "T. Device Information:",
        items: [
          "Device type",
          "Operating system",
          "IP address",
          "Unique device identifiers (e.g., IMEI or UDID)",
        ],
      },
      {
        title: "U. Location Information:",
        items: [
          "Geo-location data (if the user allows access)",
          "Delivery addresses",
          "Store preferences based on location",
        ],
      },
      {
        title: "H. Communication Preferences:",
        items: [
          "Opt-in or opt-out preferences for marketing communications",
          "Communication history with customer support",
        ],
      },
      {
        title: "III. Social Media Information:",
        items: [
          "Social media profiles (if the user logs in or connects via social media)",
          "Social connections within the app (e.g., friends' wish lists)",
        ],
      },
      {
        title: "J. Customer Feedback:",
        items: [
          "Ratings and reviews",
          "Feedback and comments",
          "Cookies and similar technologies for tracking user behavior",
          "Analytics data for improving app performance and user experience",
        ],
      },
      {
        title: "K. User-generated Content:",
        items: ["Product reviews", "User-submitted images or videos"],
      },
    ],
  },
  {
    title: "Purpose of Collection",
    content:
      "3.1 The Company collects and processes personal information for the following purposes:",
    subSections: [
      {
        title: "CC. Order Fulfillment:",
        items: [
          "To process and fulfill user orders for products and services.",
        ],
      },
      {
        title: "DD. Account Management:",
        items: [
          "To create and manage user accounts, allowing users to access and track their orders, preferences, and settings.",
        ],
      },
      {
        title: "EE. Communication:",
        items: [
          "To communicate with users regarding order confirmations, shipping updates, and customer support inquiries.",
        ],
      },
      {
        title: "FF. Personalization:",
        items: [
          "To personalize the user experience by providing tailored product recommendations, promotions, and content based on user preferences and shopping history.",
        ],
      },
      {
        title: "GG. Payment Processing:",
        items: [
          "To facilitate secure payment transactions and manage payment information for purchases.",
        ],
      },
      {
        title: "HH. User Authentication:",
        items: [
          "To verify the identity of users during account creation and login processes.",
        ],
      },
      {
        title: "II. Analytics and Improvement:",
        items: [
          "To analyze user behavior, preferences, and interactions within the app to improve services, features, and overall user experience.",
        ],
      },
      {
        title: "JJ. Marketing and Promotions:",
        items: [
          "To send marketing communications, promotions, and advertisements based on user preferences, with the option for users to opt in or out.",
        ],
      },
      {
        title: "KK. Customer Support:",
        items: [
          "To respond to customer inquiries, address concerns, and provide support related to products, orders, or account-related issues.",
        ],
      },
      {
        title: "LL. Legal Compliance:",
        items: [
          "To comply with applicable laws and regulations related to data protection, consumer rights, and other legal requirements.",
        ],
      },
      {
        title: "MM. Fraud Prevention:",
        items: [
          "To detect and prevent fraudulent activities, including unauthorized access, transactions, or account misuse.",
        ],
      },
      {
        title: "NN. Research and Development:",
        items: [
          "To conduct research and development activities aimed at improving the app's features, services, and security.",
        ],
      },
      {
        title: "OO. Social Features:",
        items: [
          "If applicable, to enable social features, such as connecting with friends, sharing wish lists, or recommending products.",
        ],
      },
      {
        title: "PP. User Feedback:",
        items: [
          "To gather user feedback, reviews, and ratings to enhance product offerings and overall customer satisfaction.",
        ],
      },
    ],
  },
];
export const privacyPolicyCanadianSections = [
  {
    title: "Consent",
    content:
      "4.1 By using the Company's services, the User consents to the collection, use, and processing of their personal information in accordance with this Agreement.",
  },
  {
    title: "Security Measures",
    content:
      "5.1 The Company will implement reasonable and appropriate security measures to protect the personal information from unauthorized access, disclosure, alteration, and destruction.",
  },
  {
    title: "Data Retention",
    content:
      "6.1 The Company will retain personal information for the duration necessary to fulfill the purposes outlined in this Agreement unless a longer retention period is required or permitted by law.",
  },
  {
    title: "Sharing of Information",
    content:
      "7.1 The Company may share personal information with third parties only for the purposes outlined in this Agreement or as required by law.",
  },
  {
    title: "User Rights",
    content: "8.1 Users have the right to:",
    subSections: [
      "- Access their personal information",
      "- Correct inaccuracies in their personal information",
      "- Withdraw consent",
      "- Request the deletion of their personal information, subject to legal requirements",
    ],
  },
  {
    title: "Changes to the Agreement",
    content:
      "9.1 The Company reserves the right to modify this Agreement at any time. Users will be notified of material changes.",
  },
  {
    title: "Governing Law",
    content:
      "10.1 This Agreement is governed by and construed in accordance with the laws of Canada, and specifically, PIPEDA.",
  },
  {
    title: "Contact Information",
    content:
      "11.1 For any inquiries or concerns regarding this Agreement or the Company's privacy practices, please contact the Company at 13475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville, Florida 32225 USA.",
  },
];

export const aussiePrivacysections = [
  {
    title: "Introduction",
    content:
      "1.1 This Agreement outlines the privacy practices and policies of the Company concerning the collection, use, and protection of personal information provided by Users.",
  },
  {
    title: "Information Collected",
    content:
      "2.1 The Company may collect and process the following types of personal information:",
    subSections: [
      {
        title: "V. Contact Information:",
        items: [
          "Full name",
          "Email address",
          "Phone number",
          "Mailing address",
        ],
      },
      {
        title: "W. Account Information:",
        items: [
          "Username",
          "Password (hashed or encrypted)",
          "Account preferences",
          "Profile picture",
        ],
      },
      {
        title: "X. Payment Information:",
        items: [
          "Credit card details",
          "Debit card details",
          "Bank account information",
          "Billing address",
        ],
      },
      {
        title: "Y. Order History:",
        items: [
          "Records of past purchases",
          "Order details (items purchased, quantities, prices)",
          "Delivery information",
        ],
      },
      {
        title: "Z. Shopping Preferences:",
        items: ["Product preferences", "Wish lists", "Saved items or carts"],
      },
      {
        title: "AA. Device Information:",
        items: [
          "Device type",
          "Operating system",
          "IP address",
          "Unique device identifiers (e.g., IMEI or UDID)",
        ],
      },
      {
        title: "BB. Location Information:",
        items: [
          "Geo-location data (if the user allows access)",
          "Delivery addresses",
          "Store preferences based on location",
        ],
      },
      {
        title: "H. Communication Preferences:",
        items: [
          "Opt-in or opt-out preferences for marketing communications",
          "Communication history with customer support",
        ],
      },
      {
        title: "IV. Social Media Information:",
        items: [
          "Social media profiles (if the user logs in or connects via social media)",
          "Social connections within the app (e.g., friends' wish lists)",
        ],
      },
      {
        title: "J. Customer Feedback:",
        items: [
          "Ratings and reviews",
          "Feedback and comments",
          "Cookies and similar technologies for tracking user behavior",
          "Analytics data for improving app performance and user experience",
        ],
      },
      {
        title: "K. User-generated Content:",
        items: ["Product reviews", "User-submitted images or videos"],
      },
    ],
  },
  {
    title: "Purpose of Collection",
    content:
      "3.1 The Company collects and processes personal information for the following purposes:",
    subSections: [
      {
        title: "QQ. Order Fulfillment:",
        items: [
          "To process and fulfill user orders for products and services.",
        ],
      },
      {
        title: "RR. Account Management:",
        items: [
          "To create and manage user accounts, allowing users to access and track their orders, preferences, and settings.",
        ],
      },
      {
        title: "SS. Communication:",
        items: [
          "To communicate with users regarding order confirmations, shipping updates, and customer support inquiries.",
        ],
      },
      {
        title: "TT. Personalization:",
        items: [
          "To personalize the user experience by providing tailored product recommendations, promotions, and content based on user preferences and shopping history.",
        ],
      },
      {
        title: "UU. Payment Processing:",
        items: [
          "To facilitate secure payment transactions and manage payment information for purchases.",
        ],
      },
      {
        title: "VV. User Authentication:",
        items: [
          "To verify the identity of users during account creation and login processes.",
        ],
      },
      {
        title: "WW. Analytics and Improvement:",
        items: [
          "To analyze user behavior, preferences, and interactions within the app to improve services, features, and overall user experience.",
        ],
      },
      {
        title: "XX. Marketing and Promotions:",
        items: [
          "To send marketing communications, promotions, and advertisements based on user preferences, with the option for users to opt in or out.",
        ],
      },
      {
        title: "YY. Customer Support:",
        items: [
          "To respond to customer inquiries, address concerns, and provide support related to products, orders, or account-related issues.",
        ],
      },
      {
        title: "ZZ. Legal Compliance:",
        items: [
          "To comply with applicable laws and regulations related to data protection, consumer rights, and other legal requirements.",
        ],
      },
      {
        title: "AAA. Fraud Prevention:",
        items: [
          "To detect and prevent fraudulent activities, including unauthorized access, transactions, or account misuse.",
        ],
      },
      {
        title: "BBB. Research and Development:",
        items: [
          "To conduct research and development activities aimed at improving the app's features, services, and security.",
        ],
      },
      {
        title: "CCC. Social Features:",
        items: [
          "If applicable, to enable social features, such as connecting with friends, sharing wish lists, or recommending products.",
        ],
      },
      {
        title: "DDD. User Feedback:",
        items: [
          "To gather user feedback, reviews, and ratings to enhance product offerings and overall customer satisfaction.",
        ],
      },
    ],
  },
];
export const privacyPolicyAussieSections = [
  {
    title: "Consent",
    content:
      "4.1 By using the Company's services, the User consents to the collection, use, and processing of their personal information in accordance with this Agreement.",
  },
  {
    title: "Security Measures",
    content:
      "5.1 The Company will implement reasonable and appropriate security measures to protect the personal information from unauthorized access, disclosure, alteration, and destruction.",
  },
  {
    title: "Data Retention",
    content:
      "6.1 The Company will retain personal information for the duration necessary to fulfill the purposes outlined in this Agreement unless a longer retention period is required or permitted by law.",
  },
  {
    title: "Sharing of Information",
    content:
      "7.1 The Company may share personal information with third parties only for the purposes outlined in this Agreement or as required by law.",
  },
  {
    title: "User Rights",
    content: "8.1 Users have the right to:",
    subSections: [
      "- Access their personal information",
      "- Correct inaccuracies in their personal information",
      "- Withdraw consent",
      "- Request the deletion of their personal information, subject to legal requirements",
    ],
  },
  {
    title: "Changes to the Agreement",
    content:
      "9.1 The Company reserves the right to modify this Agreement at any time. Users will be notified of material changes.",
  },
  {
    title: "Governing Law",
    content:
      "10.1 This Agreement is governed by and construed in accordance with the laws of Australia, and specifically, the Privacy Act 1988 (Cth).",
  },
  {
    title: "Contact Information",
    content:
      "11.1 For any inquiries or concerns regarding this Agreement or the Company's privacy practices, please contact the Company at 13475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville, Florida 32225. ",
  },
];
