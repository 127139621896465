export const dataRetentionPolicy = [
  {
    section: "1. Purpose",
    content: [
      '1.1 This Data Retention Policy ("Policy") outlines the guidelines and procedures for the retention and disposal of data by Ya’Mar, a Store-front Reselling Application, hereinafter referred to as the "Company."',
    ],
  },
  {
    section: "2. Scope",
    content: [
      "2.1 This Policy applies to all data, including electronic and paper records, generated or maintained by the Company and its employees.",
    ],
  },
  {
    section: "3. Types of Data Covered",
    content: [
      "3.1 The types of data covered by this Policy include but are not limited to:",
      "a. Customer information.",
      "b. Employee records.",
      "c. Financial records.",
      "d. Communications (emails, instant messages, etc.).",
      "e. Operational data.",
      "f. Any other data collected, processed, or stored by the Company.",
    ],
  },
  {
    section: "4. Data Ownership and Responsibility",
    content: [
      "4.1 The Company is the owner and custodian of all data generated or maintained in the course of its business activities.",
      "4.2 Each department head is responsible for overseeing the proper implementation of this Policy within their respective areas.",
    ],
  },
  {
    section: "5. Data Retention Periods",
    content: [
      "5.1 The retention periods for different types of data are as follows:",
      "a. Customer Information: Retained for 24 months.",
      "b. Employee Records: Retained for 24 months after the termination of employment.",
      "c. Financial Records: Retained for 24 months.",
      "d. Communications: Retained for 24 months.",
      "e. Operational Data: Retained for 24 months.",
      "f. Other Data: Retained for 24 months.",
      "5.2 Data that has met the specified retention period and is no longer required for legal, operational, or historical purposes should be securely disposed of as outlined in Section 6 of this Policy.",
    ],
  },
  {
    section: "6. Data Disposal Procedures",
    content: [
      "6.1 The disposal of data will be conducted in a manner that ensures the complete destruction of the information, rendering it irrecoverable.",
      "6.2 Electronic data will be permanently deleted using secure deletion methods or overwritten to prevent data recovery.",
      "6.3 Physical documents will be shredded or disposed of using secure waste disposal services.",
    ],
  },
  {
    section: "7. Legal and Regulatory Compliance",
    content: [
      "7.1 The Company will retain data in compliance with applicable federal, Florida state, and local laws and regulations.",
      "7.2 In the event of a legal or regulatory requirement to preserve data beyond the specified retention period, the Company will review and adjust its data retention practices accordingly.",
    ],
  },
  {
    section: "8. Data Audits and Reviews",
    content: [
      "8.1 The Company will conduct periodic audits and reviews of its data retention practices to ensure compliance with this Policy and relevant laws and regulations.",
      "8.2 Adjustments to data retention periods or disposal procedures may be made based on the outcomes of these audits and reviews.",
    ],
  },
  {
    section: "9. Exceptions",
    content: [
      "9.1 The US Courts may grant exceptions to this Policy under exceptional circumstances. All exceptions must be documented and approved in writing.",
    ],
  },
  {
    section: "10. Policy Review and Updates",
    content: [
      "10.1 This Policy will be reviewed annually and updated as necessary to ensure its effectiveness and compliance with changing laws and business needs.",
    ],
  },
  {
    section: "11. Contact Information",
    content: [
      "11.1 For any inquiries or concerns regarding this Data Retention Policy, please contact 3475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville, Florida 32225 USA.",
    ],
  },
];
