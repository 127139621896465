import React from "react";
import { Col, Container, Row } from "reactstrap";

const GeolocationPolicies = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={10} className="mx-auto mt-5">
          <div className="text-center">
            <h3>
              Ya’Mar Geolocation Private Policy and Data Retention Policy{" "}
            </h3>
            <i>Effective as of December 11, 2023</i>
          </div>
          <p className="my-5">
            This Geolocation Privacy Policy ("Policy") explains how Ya’Mar, a
            Store-front Reselling Application ("Company," "we," "us," or "our")
            collects, uses, and protects geolocation information obtained from
            users of our services. By using our services, you consent to the
            practices described in this Policy.
          </p>

          <i>1. Information Collected</i>
          <p className="mt-3">
            1.1 We may collect and process the following types of geolocation
            information:
          </p>
          <p className="mt-3">
            {" "}
            a. Device Location: We may collect the physical location of your
            device using technologies such as GPS, Wi-Fi, or mobile network
            information.
          </p>

          <p className="mt-3">
            b. IP Address: We may collect your IP address, which can provide an
            approximate location.
          </p>
          <i>2. Purpose of Collection</i>
          <p className="mt-3">
            2.1 We collect geolocation information for the following purposes:
          </p>
          <div>
            <p className="mt-3">
              <u>a. Service Provision:</u> To provide location-based services,
              features, and content.
            </p>

            <p className="mt-3">
              {" "}
              <u>b. Analytics:</u>
              To analyze and improve the performance, functionality, and
              usability of our services.
            </p>

            <p className="mt-3">
              {" "}
              <u>c. Personalization:</u>
              To personalize content and recommendations based on your location.
            </p>

            <p className="mt-3">
              <u>d. Security:</u>
              To enhance the security of our services, including fraud detection
              and prevention.
            </p>
          </div>
          <div>
            <i>3. Consent</i>
            <p className="mt-3">
              3.1 By using our services, you consent to the collection, use, and
              processing of your geolocation information as described in this
              Policy. You may withdraw your consent at any time by adjusting
              your device settings or contacting us as outlined in Section 8.
            </p>

            <i>4. Security Measures</i>
            <p className="mt-3">
              4.1 We implement reasonable and appropriate security measures to
              protect geolocation information from unauthorized access,
              disclosure, alteration, and destruction.
            </p>

            <i>5. Data Retention</i>
            <p className="mt-3">
              5.1 Geolocation information will be retained for the duration
              necessary to fulfill the purposes outlined in this Policy, unless
              a longer retention period is required or permitted by law.
            </p>

            <i>6. Sharing of Information</i>
            <p className="mt-3">
              6.1 We may share geolocation information with third parties only
              for the purposes outlined in this Policy or as required by law.
            </p>

            <i>7. User Controls</i>
            <p className="mt-3">
              7.1 You have the right to control the collection and use of your
              geolocation information:
            </p>
            <p className="mt-3">
              a. Device Settings: You can adjust the location settings on your
              device to control the sharing of location information.
            </p>
            <p className="mt-3">
              b. Opt-Out: You may choose to opt-out of location-based services
              or features, but this may impact the functionality of certain
              services.
            </p>

            <i>8. Changes to the Policy</i>
            <p className="mt-3">
              8.1 We reserve the right to modify this Policy at any time. Users
              will be notified of material changes. Continued use of our
              services after such changes constitutes acceptance of the revised
              Policy.
            </p>

            <i>9. Governing Law</i>
            <p className="mt-3">
              9.1 This Policy is governed by and construed in accordance with
              the laws of Florida.
            </p>

            <i>10. Contact Information</i>
            <p className="mt-3">
              10.1 For any inquiries or concerns regarding this Geolocation
              Privacy Policy, please contact the Company at 3475 Atlantic Blvd.
              Unit 8 Suite M852 Jacksonville, Florida 32225.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GeolocationPolicies;
