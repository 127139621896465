const initialValue = {
    website_data: []
};

const reducers = (state = initialValue, action) => {
    switch (action.type) {
        case "WEBSITE_DATA": {
            return {
                ...state,
                website_data: action.payload
            };
        }
        default:
            return state;
    }
};

export default reducers;
