import React from "react";
import { Col, Container, Row } from "reactstrap";

const DataProtectionPolicy = () => {
  return (
    <Container className="my-5">
      <Row>
        <Col md={10} className="mx-auto mt-5">
          <div className="text-center">
            <h3>Ya’Mar Data Protection Policy</h3>
            <i>Effective as of December 11, 2023</i>
          </div>
          <i>1. Introduction</i>
          <p className="mt-3">
            1.1 Ya’Mar, hereinafter referred to as the "Company," is committed
            to protecting the privacy and security of personal data in
            accordance with the General Data Protection Regulation (GDPR) and
            other applicable data protection laws in the United Kingdom.
          </p>

          <i>2. Scope</i>
          <p className="mt-3">
            2.1 This policy applies to all personal data processed by the
            Company, regardless of the form or medium in which it is processed.
          </p>
          <i>3. Definitions</i>
          <p className="mt-3">3.1 For the purposes of this policy:</p>

          <p className="mt-3">
            <u>Personal Data:</u> Any information relating to an identified or
            identifiable natural person.
          </p>

          <p className="mt-3">
            <u>Data Subject</u>: The individual to whom the personal data
            relates.
          </p>
          <p className="mt-3">
            <u>Processing</u> : Any operation or set of operations performed on
            personal data.
          </p>

          <div>
            <i>4. Principles of Data Protection</i>
            <p className="mt-3">
              4.1 The Company is committed to adhering to the principles of data
              protection outlined in the GDPR. Personal data will be:
            </p>
            <p className="mt-3">
              a. Processed lawfully, fairly, and transparently.
            </p>
            <p className="mt-3">
              b. Collected for specified, explicit, and legitimate purposes and
              not further processed in a manner incompatible with those
              purposes.
            </p>
            <p className="mt-3">
              c. Adequate, relevant, and limited to what is necessary for the
              purposes for which it is processed.
            </p>
            <p className="mt-3">
              d. Accurate and, where necessary, kept up to date.
            </p>
            <p className="mt-3">
              e. Retained only for as long as necessary for the purposes for
              which it was collected.
            </p>
            <p className="mt-3">
              f. Processed securely and with appropriate measures to ensure the
              confidentiality, integrity, and availability of personal data.
            </p>
          </div>

          <div>
            <i>5. Data Collection and Processing</i>
            <p className="mt-3">5.1 The Company will:</p>
            <p className="mt-3">
              a. Clearly communicate the purposes for which personal data is
              collected.
            </p>
            <p className="mt-3">
              b. Obtain explicit consent for processing personal data, where
              required.
            </p>
            <p className="mt-3">
              c. Limit the collection and processing of personal data to what is
              strictly necessary for the stated purposes.
            </p>
          </div>

          <div>
            <i>6. Data Subject Rights</i>
            <p className="mt-3">
              6.1 The Company recognizes and respects the rights of data
              subjects as outlined in the GDPR, including the right to access,
              rectify, erase, restrict processing, data portability, and object
              to processing.
            </p>
          </div>

          <div>
            <i>7. Data Security</i>
            <p className="mt-3">
              7.1 The Company will implement appropriate technical and
              organizational measures to ensure the security of personal data,
              including protection against unauthorized or unlawful processing
              and accidental loss, destruction, or damage.
            </p>
          </div>
          <div>
            <i>8. Data Breach Response</i>
            <p className="mt-3">
              8.1 The Company has procedures in place to detect, report, and
              investigate any personal data breaches. In the event of a breach,
              affected data subjects and relevant authorities will be notified
              as required by law.
            </p>

            <i>9. Data Protection Officer</i>
            <p className="mt-3">
              9.1 The Company has appointed a Data Protection Officer (DPO) who
              is responsible for ensuring compliance with data protection laws
              and regulations.
            </p>

            <i>10. Training and Awareness</i>
            <p className="mt-3">
              10.1 The Company will provide training and raise awareness among
              employees regarding their responsibilities under this data
              protection policy and relevant data protection laws.
            </p>

            <i>11. Policy Review and Updates</i>
            <p className="mt-3">
              11.1 This policy will be regularly reviewed and updated to ensure
              ongoing compliance with data protection laws and the changing
              needs of the Company.
            </p>

            <i>12. Contact Information</i>
            <p className="mt-3">
              12.1 For any inquiries or concerns related to data protection,
              please contact the Data Protection Officer at 3475 Atlantic Blvd.
              Unit 8 Suite M852 Jacksonville, Florida 32225 USA.
            </p>
          </div>
          <hr />
          <h3 className="text-center">
            Addendum for GDPR/ Personal Data Protection Policy for France
            Politique de Protection des Données Personnelles
          </h3>
          <i>1. Introduction</i>
          <p className="mt-3">
            1.1 Ya’Mar, ci-après dénommée la "Société", s'engage à protéger la
            vie privée et la sécurité des données personnelles conformément au
            Règlement Général sur la Protection des Données (RGPD) et aux autres
            lois françaises relatives à la protection des données.
          </p>

          <i>2. Champ d'Application</i>
          <p className="mt-3">
            2.1 Cette politique s'applique à toutes les données personnelles
            traitées par la Société, quelle que soit leur forme ou leur moyen de
            traitement.
          </p>
          <i>3. Définitions</i>
          <p className="mt-3">3.1 Aux fins de cette politique :</p>

          <p className="mt-3">
            <u>Données Personnelles : </u>Toute information se rapportant à une
            personne physique identifiée ou identifiable.
          </p>

          <p className="mt-3">
            <u>Personne Concernée :</u>: : L'individu auquel se rapportent les
            données personnelles.
          </p>
          <p className="mt-3">
            <u>Traitement : </u> : Toute opération ou ensemble d'opérations
            effectuées sur des données personnelles.
          </p>

          <div>
            <i>4. Principes de Protection des Données</i>
            <p className="mt-3">
              4.1 La Société s'engage à respecter les principes de protection
              des données énoncés dans le RGPD. Les données personnelles seront
              :
            </p>
            <p className="mt-3">
              a. Traitées licitement, équitablement et de manière transparente.
            </p>
            <p className="mt-3">
              b. Collectées pour des finalités spécifiques, explicites et
              légitimes et ne seront pas traitées ultérieurement de manière
              incompatible avec ces finalités.
            </p>
            <p className="mt-3">
              c. Adéquates, pertinentes et limitées à ce qui est nécessaire aux
              fins pour lesquelles elles sont traitées.
            </p>
            <p className="mt-3">d. Exactes et, si nécessaire, tenues à jour.</p>
            <p className="mt-3">
              e. Conservées uniquement aussi longtemps que nécessaire pour les
              finalités pour lesquelles elles ont été collectées.
            </p>
            <p className="mt-3">
              f. Traitées de manière sécurisée avec des mesures appropriées pour
              assurer la confidentialité, l'intégrité et la disponibilité des
              données personnelles.
            </p>
            <i>5. Collecte et Traitement des Données</i>
            <p className="mt-3">5.1 La Société s'engage à :</p>
            <p className="mt-3">
              a. Communiquer clairement les finalités de la collecte des données
              personnelles.
            </p>
            <p className="mt-3">
              b. Obtenir le consentement explicite pour le traitement des
              données personnelles, lorsque cela est requis.
            </p>
            <p className="mt-3">
              c. Limiter la collecte et le traitement des données personnelles à
              ce qui est strictement nécessaire pour les finalités déclarées.
            </p>

            <div>
              <i>6. Droits des Personnes Concernées</i>
              <p className="mt-3">
                6.1 La Société reconnaît et respecte les droits des personnes
                concernées tels que définis dans le RGPD, y compris le droit
                d'accès, de rectification, d'effacement, de limitation du
                traitement, de portabilité des données et d'opposition au
                traitement.
              </p>

              <i>7. Sécurité des Données</i>
              <p className="mt-3">
                7.1 La Société mettra en œuvre des mesures techniques et
                organisationnelles appropriées pour assurer la sécurité des
                données personnelles, y compris la protection contre tout
                traitement non autorisé ou illicite et contre la perte
                accidentelle, la destruction ou les dommages.
              </p>

              <i>8. Gestion des Violations de Données</i>
              <p className="mt-3">
                8.1 La Société dispose de procédures pour détecter, signaler et
                enquêter sur toute violation de données personnelles. En cas de
                violation, les personnes concernées et les autorités compétentes
                seront informées conformément à la loi.
              </p>

              <i>9. Délégué à la Protection des Données</i>
              <p className="mt-3">
                9.1 La Société a désigné un Délégué à la Protection des Données
                (DPD) responsable de veiller à la conformité avec les lois et
                règlements de protection des données.
              </p>

              <i>10. Formation et Sensibilisation</i>
              <p className="mt-3">
                10.1 La Société fournira une formation et sensibilisera les
                employés à leurs responsabilités en vertu de cette politique de
                protection des données et des lois pertinentes sur la protection
                des données.
              </p>

              <i>11. Révision et Mises à Jour de la Politique</i>
              <p className="mt-3">
                11.1 Cette politique sera régulièrement examinée et mise à jour
                pour assurer la conformité continue avec les lois sur la
                protection des données et les besoins changeants de la Société.
              </p>

              <i>12. Coordonnées</i>
              <p className="mt-3">
                12.1 Pour toute question ou préoccupation liée à la protection
                des données, veuillez contacter le Délégué à la Protection des
                Données à 3475 Atlantic Blvd. Unit 8 Suite M852 Jacksonville,
                Florida 32225.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default DataProtectionPolicy;
