import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/imgs/Logo.png";
import { FaTiktok } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
const Footer = () => {
  const router = useLocation();
  return (
    <div>
      <div class=" pt-5  footer bg-purple text-white px-0">
        <div class="container px-0">
          <div class="row justify-content-between align-items-top px-0 mx-0">
            <div class="col-lg-5  about-company">
              <div className="mb-4">
                <Link
                  className="nav-link active text-white"
                  aria-current="page"
                  to="/"
                >
                  <img src={logo} width={100} alt="" />
                </Link>
              </div>
            </div>
            <div class="col-lg-3  links">
              <h4 class="mt-lg-0 mt-sm-3 mb-0">Links</h4>
              <div className="d-flex flex-column text-left ">
                <Link className="nav-link text-white" to="/about">
                  About Us
                </Link>
                <Link className="nav-link text-white" to="/contact">
                  Contact Us
                </Link>
                {router.pathname === "/privacy-agreement" ? (
                  <>
                    <h4 className="mb-0 my-3">
                      <a
                        className="text-decoration-none text-white "
                        href="/privacy-agreement"
                      >
                        Privacy
                      </a>
                    </h4>

                    <Link
                      className="nav-link text-white"
                      to="/protection-policy"
                    >
                      Personal Data Protection
                    </Link>
                    <Link
                      className="nav-link text-white  "
                      to="/retention-policy"
                    >
                      Data Retention
                    </Link>
                    <Link className="nav-link text-white" to="/geo-policies">
                      GeoLocation Policies
                    </Link>
                  </>
                ) : (
                  ""
                )}

              </div>

            </div>
            <div class="col-lg-3  links">
              <h4 class="mt-lg-0 mt-sm-3 mb-3">Social Links</h4>
              <div className="d-flex gap-3">
                <a className="text-white " href='https://www.tiktok.com/@yamar_usa' target="_blank" rel="noreferrer">
                  <FaTiktok size={30} />
                </a>
                <a className="text-white " href='https://www.instagram.com/yamar_usa' target="_blank" rel="noreferrer">
                  <RiInstagramFill size={30} />
                </a>
                <a className="text-white " href="https://www.facebook.com/yamarusa" target="_blank" rel="noreferrer">
                  <FaFacebook size={30} />
                </a>

              </div>
            </div>
          </div>
          <div class="py-3 ">
            <div class="text-center">
              <small>
                © 2024 YaMar LLC All Rights Reserved |{" "}
                <a
                  className="text-decoration-none text-white mx-2"
                  href="/terms-conditions"
                >
                  Terms
                </a>
                <a
                  className="text-decoration-none text-white mx-2"
                  href="/privacy-agreement"
                >
                  Privacy
                </a>

              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
