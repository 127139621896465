import React from "react";
import { Col, Container, Row } from "reactstrap";
import {
  aussiePrivacysections,
  canadianPrivacysections,
  indianPrivacysections,
  privacyPolicyAussieSections,
  privacyPolicyCanadianSections,
  privacyPolicyIndianSections,
  privacyPolicySections,
  sections,
} from "../data";

const PrivacyAgreement = () => {
  return (
    <Container>
      <Row className="mt-5">
        <Col md={10} className="mx-auto">
          <div className="text-center">
            <h2 className=" mt-5">Ya’Mar Privacy Agreement</h2>
            <p>Effective as of December 11, 2023</p>
          </div>
          <p>
            This Privacy Agreement ("Agreement") is entered into as of December
            11, 2023, by and between Ya’Mar , a Store-front Reselling
            Application having its registered office at 3475 Atlantic Blvd. Unit
            8 Suite M852 Jacksonville, Florida 32225 ("Company") and the user of
            the Company's services ("User").
          </p>

          {sections.map((section, index) => (
            <div key={index}>
              <i className="my-5 fw-bold">{`${index + 1}. ${
                section.title
              } `}</i>
              <p className="mt-3">{section.content}</p>

              {section.subSections &&
                section.subSections.map((subSection, subIndex) => (
                  <div key={subIndex} className="mb-3">
                    <p className="mx-3">{subSection.title}</p>
                    {subSection.items.map((item, itemIndex) => (
                      <p key={itemIndex} className="mb-0">
                        {item}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          ))}

          {privacyPolicySections.map((section, index) => (
            <div key={index}>
              <i>{`${index + 4}. ${section.title}`}</i>
              <p className="mt-3">{section.content}</p>

              {section.subSections && section.subSections.length > 0 && (
                <div>
                  {section.subSections.map((subSection, subIndex) => (
                    <p key={subIndex} className="mb-0">
                      {subSection}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}

          <p>
            By using the Company's services, the User acknowledges that they
            have read, understood, and agreed to the terms of this Privacy
            Agreement.
          </p>
        </Col>
        <Col md={10} className="mx-auto">
          <div className="text-center">
            <h2 className=" mt-5">ADDENDUM For Users from India </h2>
          </div>

          {indianPrivacysections.map((section, index) => (
            <div key={index}>
              <i className="my-5 fw-bold">{`${index + 1}. ${
                section.title
              } `}</i>
              <p className="mt-3">{section.content}</p>

              {section.subSections &&
                section.subSections.map((subSection, subIndex) => (
                  <div key={subIndex} className="mb-3">
                    <p className="mx-3">{subSection.title}</p>
                    {subSection.items.map((item, itemIndex) => (
                      <p key={itemIndex} className="mb-0">
                        {item}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          ))}

          {privacyPolicyIndianSections.map((section, index) => (
            <div key={index}>
              <i>{`${index + 4}. ${section.title}`}</i>
              <p className="mt-3">{section.content}</p>

              {section.subSections && section.subSections.length > 0 && (
                <div>
                  {section.subSections.map((subSection, subIndex) => (
                    <p key={subIndex} className="mb-0">
                      {subSection}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}

          <p>
            By using the Company's services, the User acknowledges that they
            have read, understood, and agreed to the terms of this Privacy
            Agreement.
          </p>
        </Col>
        <Col md={10} className="mx-auto">
          <div className="text-center">
            <h2 className=" mt-5">ADDENDUM For Users from Canada </h2>
          </div>

          {canadianPrivacysections.map((section, index) => (
            <div key={index}>
              <i className=" fw-bold">{`${index + 1}. ${section.title} `}</i>
              <p className="my-3">{section.content}</p>

              {section.subSections &&
                section.subSections.map((subSection, subIndex) => (
                  <div key={subIndex} className="mb-3">
                    <p className="mx-3">{subSection.title}</p>
                    {subSection.items.map((item, itemIndex) => (
                      <p key={itemIndex} className="mb-0">
                        {item}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          ))}

          {privacyPolicyCanadianSections.map((section, index) => (
            <div key={index}>
              <i>{`${index + 4}. ${section.title}`}</i>
              <p className="mt-3">{section.content}</p>

              {section.subSections && section.subSections.length > 0 && (
                <div>
                  {section.subSections.map((subSection, subIndex) => (
                    <p key={subIndex} className="mb-0">
                      {subSection}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}

          <p>
            By using the Company's services, the User acknowledges that they
            have read, understood, and agreed to the terms of this Privacy
            Agreement.
          </p>
        </Col>
        <Col md={10} className="mx-auto">
          <div className="text-center">
            <h2 className=" mt-5">ADDENDUM For Users from Austrailia </h2>
          </div>

          {aussiePrivacysections.map((section, index) => (
            <div key={index}>
              <i className=" fw-bold">{`${index + 1}. ${section.title} `}</i>
              <p className="my-3">{section.content}</p>

              {section.subSections &&
                section.subSections.map((subSection, subIndex) => (
                  <div key={subIndex} className="mb-3">
                    <p className="mx-3">{subSection.title}</p>
                    {subSection.items.map((item, itemIndex) => (
                      <p key={itemIndex} className="mb-0">
                        {item}
                      </p>
                    ))}
                  </div>
                ))}
            </div>
          ))}

          {privacyPolicyAussieSections.map((section, index) => (
            <div key={index}>
              <i>{`${index + 4}. ${section.title}`}</i>
              <p className="mt-3">{section.content}</p>

              {section.subSections && section.subSections.length > 0 && (
                <div>
                  {section.subSections.map((subSection, subIndex) => (
                    <p key={subIndex} className="mb-0">
                      {subSection}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))}

          <p>
            By using the Company's services, the User acknowledges that they
            have read, understood, and agreed to the terms of this Privacy
            Agreement.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyAgreement;
